<template>
  <a-layout class="container" style="height: 100%">
    <a-layout-header style="background-color: #fff">
      <div class="logo" @click="toHome">
        <img src="../assets/logo.png" alt="" />
        <h3>店管家</h3>
      </div>
      <a-menu
        v-model="currentMenu"
        theme="light"
        mode="horizontal"
        :style="{ lineHeight: '64px' }"
      >
        <a-menu-item
          v-for="item in menus"
          :key="item.key"
          @click="changeMenu"
          >{{ item.name }}</a-menu-item
        >
        <a-sub-menu style="float: right">
          <div slot="title">
            <span style="margin-right: 4px"
              >用户名: {{ (userInfo && userInfo.username) || "--" }}</span
            >
            <a-icon type="down" />
          </div>
          <a-menu-item key="setPassword" @click="setPassword"
            >修改密码</a-menu-item
          >
          <a-menu-item key="logout" @click="logout">退出账号</a-menu-item>
        </a-sub-menu>
      </a-menu>
    </a-layout-header>
    <a-layout>
      <a-layout-sider width="200" style="background: #fff" class="sider__main">
        <a-menu
          v-model="currentSubmenu"
          mode="inline"
          :style="{ height: '100%', borderRight: 0 }"
        >
          <a-menu-item
            v-for="item in submenus"
            :key="item.key"
            @click="changeSubmenu"
          >
            <!-- <a-icon :type="item.icon" /> -->
            <icon-font
              :type="item.icon"
              style="font-size: 20px; vertical-align: text-bottom"
            />
            <span>{{ item.name }}</span>
          </a-menu-item>
        </a-menu>
      </a-layout-sider>

      <a-layout style="padding: 0 24px 24px">
        <a-breadcrumb style="margin: 16px 0">
          <a-breadcrumb-item v-for="item in routeNodes" :key="item">{{
            item
          }}</a-breadcrumb-item>
        </a-breadcrumb>

        <a-layout-content>
          <router-view v-if="isRouterAlive" />
        </a-layout-content>
      </a-layout>
    </a-layout>
  </a-layout>
</template>

<script>
import menus from "@/menus";
import { mapState } from "vuex";


export default {
  name: "ConsoleLayout",
  data() {
    return {
      menus,
      submenus: [],
      currentMenu: [],
      currentSubmenu: [],
      isRouterAlive: true,
    };
  },
  components: {
  },
  computed: {
    ...mapState(["userInfo"]),
    routeNodes() {
      // 路由节点
      let nodes = this.$route.path.split("/").filter((value) => value != "");
      let nodeNames = [];
      if (nodes.length > 0) {
        // 获取主菜单
        let node = nodes.shift();
        let index = this.menus.findIndex((item) => item.key == node);
        let submenus = this.menus[index].children;
        nodeNames.push(this.menus[index].name);

        // 获取子菜单
        for (node of nodes) {
          index = submenus.findIndex((item) => item.key == node);
          index > -1 ? nodeNames.push(submenus[index].name) : null;
        }
      }
      return nodeNames;
    },
  },
  watch: {
    $route() {
      this.initialize();
    },
  },
  methods: {
    toHome() {
      location.href = "/";
    },
    initialize() {
      // 获取主菜单, 子菜单列表
      let nodes = this.$route.path.split("/").filter((value) => value != "");
      let index = this.menus.findIndex((item) => item.key == nodes[0]);
      if (index != -1) {
        this.currentMenu = [this.menus[index].key];
        this.submenus = this.menus[index].children;
      }

      // 获取子菜单
      // console.log(this.submenus, 'this.submenus', this.$route, this.menus)
      index = this.submenus.findIndex((item) => item.key == nodes[1]);
      if (index != -1) {
        this.currentSubmenu = [this.submenus[index].key];
      }
    },
    changeMenu({ key }) {
      // if (key == this.currentMenu[0]) {
      //   return;
      // }

      let index = this.menus.findIndex((item) => item.key == key);
      if (index != -1) {
        this.currentMenu = [this.menus[index].key];
        this.submenus = this.menus[index].children;
        this.currentSubmenu = [this.submenus[0].key];
        this.$router.push(`/${this.currentMenu[0]}/${this.currentSubmenu[0]}`);
      }
    },
    changeSubmenu({ key }) {
      if (key == this.currentSubmenu[0]) {
        this.reload();
        // return
      }

      let index = this.submenus.findIndex((item) => item.key == key);
      if (index != -1) {
        this.currentSubmenu = [this.submenus[index].key];
        let str = `/${this.currentMenu[0]}/${this.currentSubmenu[0]}`;
        if (this.$route.path !== str) {
          this.$router.push({
            path: `/${this.currentMenu[0]}/${this.currentSubmenu[0]}`,
          });
        } else {
          // 订单列表清空query参数
          if (this.$route.path.includes("order/list") && this.$route.query && Object.keys(this.$route.query).length > 0) {
            this.$router.push({
              path: `/${this.currentMenu[0]}/${this.currentSubmenu[0]}`,
              query: {},
            });
          }
        }
      }
    },
    setPassword() {
      this.$router.push("/user/set_password");
    },
    logout() {
      this.$store.dispatch("logout").then((res) => {
        this.$router.push("/user/login");
      });
    },
    reload() {
      // 页面重载
      this.isRouterAlive = false;
      this.$nextTick(() => (this.isRouterAlive = true));
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>

<style scoped lang="less">
.container .logo {
  width: 120px;
  height: 31px;
  // background: rgba(0, 0, 0, 0.2);
  margin: 16px 28px 16px 0;
  float: left;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  position: relative;
  cursor: pointer;
  > img {
    display: inline-block;
    width: 50px;
    height: 50px;
    position: absolute;
    left: 0;
    transform: translateX(-50%);
  }
  h3 {
    line-height: 31px;
    margin-bottom: 0;
    font-size: 20px;
    color: #1890ff;
    font-weight: bold;
    white-space: nowrap;
    padding-left: 30px;
  }
}
.sider__main {
  border-top: 1px solid #f0f2f5;
}
</style>